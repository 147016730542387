// compost_styles.scss

.note-container {
  background-color: #f5f2e8; // Light, warm background color
  color: #4e4e3e; // Dark, desaturated green-tinted text
  padding: 1.5rem; // Increased padding for a more relaxed layout
  max-width: 800px; // Limit the content width for better readability
  margin: 0 auto; // Center the content within the page
  border-radius: 20px; /* Adjust the value to your desired roundness */

  .footnotes, .footnotes ol, .footnotes p {
    color: inherit;
  }

  &.bruck {
    background-color: #ddd7c4;
    color: #8c8c7e;
  }

  &.tinkering {
    background-color: #e2ddc9;
    color: #7e7e6e;
  }

  &.roughingOut {
    background-color: #e6e2d2;
    color: #6d6d5e;
  }

  &.moulding {
    background-color: #ece8dc;
    color: #5e5e4e;
  }

  .footnotes {
    font-size: 0.9rem;
    border-top: 1px solid rgba(0, 0, 0, 0.25);

    ol {
      margin-left: 1.5rem;
    }

    li {
      margin-bottom: 0.5rem;
    }

    p {
      margin-bottom: 0.5rem;
    }
  }
}

.note-container blockquote {
  background-color: rgba(255, 255, 255, 0.5);
  padding: 15px;
  color: inherit;
}