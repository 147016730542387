// Compost section overrides

.garden {

  // Typography

  h1, h2 {
    font-family: "Poetsen One", sans-serif;
    color: #606060; /* Grey color for headers to indicate draft status */
  }

  p, li {
    font-size: 105%; // Slightly larger font size
    line-height: 1.6; // Increased line spacing for readability
  }

  .last-updated, .tags {
    font-size: 105%; // Slightly larger font size
    line-height: 1.6; // Increased line spacing for readability
  }

  a {
    color: #617a55; // Olive green links

    &:hover {
      color: #92b27c; // Lighter olive green on hover
    }
  }

    // Layout and spacing
    max-width: 800px; // Limit the content width for better readability
    margin: 0 auto; // Center the content within the page
  }