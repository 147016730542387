// Set the font family for h1 headings
h1,
h2 {
  font-family: "Freeman", sans-serif; // Might need a fall-back?
  font-style: normal;
}

// Override h1 styles
h1 {
    font-size: 2.5rem; // Desired font-size
    font-weight: bold; // Desired font-weight
    // Add other styles as needed
}

h2,
h3,
h4,
h5,
h6 {
  color: #333333; // Add other h2 styles as needed
  margin-top: 1rem; /* Added top margin to create space between heading and previous content */
  margin-bottom: 1rem;
}

// Override other heading levels
h2 {
  font-size: 1.75rem;
  letter-spacing: 0.25px;
}

h3 {
  font-size: 1.5rem;
// Add other h3 styles as needed
}

h4 {
  font-size: 1.25rem;
  line-height: $base-line-height;
}

h5,
h6 {
  font-size: 1.10rem;
}

// Override .post-title and .page-heading styles (used in page.html, home.html for page title))
.post-title,
.page-heading {
  font-size: 2.5rem;
  font-weight: bold;
  // Add other styles as needed
}

// Override .post-list-heading styles (used in home.html for "Posts" heading)
.content .post-list-heading,
.main .post-list-heading {
    font-size: 2rem;
    // Add other styles as needed
}

// Override post-header and page-heading spacing
.content h1,
.main h1 {
    margin-top: 0;
    margin-bottom: 1rem; // adjust as needed
  }

body {
  line-height: $base-line-height;
  
  strong, b {
    font-weight: $bold-font-weight;
  }  
}

// Match home and post content widths
.home,
.post {
  max-width: 800px; // adjust as needed
  margin-left: auto;
  margin-right: auto;
}

.main h1.page-heading, 
.content h1.post-title {
  line-height: 1; // Example line height
}

.img-padded {
  max-width: 100%;
  height: auto;
  padding: 10px 0; /* 10px padding top and bottom, 0 padding left and right */
}

// Custom bibliography style

.custom-bibliography {
  text-align: left;
  padding-left: 1.5em;
  text-indent: -1.5em;
  line-height: 1.25;
  margin-bottom: 1em;
  font-size: 0.9em; /* Slightly smaller than baseline */
}

.custom-bibliography a:hover {
  text-decoration: underline;
}