@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 100 900;
  src: url('../fonts/inter/font-files/InterVariable.woff2') format('woff2-variations');
}

@font-face {
  font-family: 'Inter';
  font-style: italic;
  font-weight: 100 900;
  src: url('../fonts/inter/font-files/InterVariable-Italic.woff2') format('woff2-variations');
}

  // Define additional @font-face rules for other weights and styles
