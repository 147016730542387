html {
  font-size: $base-font-size;
}

/**
 * Reset some basic elements
 */
body, h1, h2, h3, h4, h5, h6,
p, blockquote, pre, hr,
dl, dd, ol, ul, figure {
  margin: 0;
  padding: 0;

}



/**
 * Basic styling
 */
body {
  font: $base-font-weight #{$base-font-size}/#{$base-line-height} $base-font-family;
  color: $text-color;
  background-color: $background-color;
  -webkit-text-size-adjust: 100%;
  -webkit-font-feature-settings: "kern" 1;
     -moz-font-feature-settings: "kern" 1;
       -o-font-feature-settings: "kern" 1;
          font-feature-settings: "kern" 1;
  font-kerning: normal;
  display: flex;
  min-height: 100vh;
  flex-direction: column;
  overflow-wrap: break-word;
}

/**
 * Set `margin-bottom` to maintain vertical rhythm
 */
h1, h2, h3, h4, h5, h6,
p, blockquote, pre,
ul, ol, dl, figure,
%vertical-rhythm {
  margin-bottom: $spacing-unit * .5;
}

hr {
  margin-top: $spacing-unit;
  margin-bottom: $spacing-unit;
}

/**
 * `main` element
 */
main {
  display: block; /* Default value of `display` of `main` element is 'inline' in IE 11. */
}



/**
 * Images
 */
img {
  max-width: 100%;
  vertical-align: middle;
}



/**
 * Figures
 */
figure > img {
  display: block;
}

figcaption {
  font-size: $small-font-size;
}



/**
 * Lists
 */
ul, ol {
  margin-left: $spacing-unit;
}

li {
  margin-bottom: 0.5rem;
  > ul,
  > ol {
    margin-bottom: 0;
  }
}



/**
 * Headings
 */
h1, h2, h3, h4, h5, h6 {
  font-weight: $base-font-weight;
}



/**
 * Links
 */
a {
  color: $link-base-color;
  text-decoration: none;

  &:visited {
    color: $link-visited-color;
  }

  &:hover {
    color: $link-hover-color;
    text-decoration: underline;
  }

  .social-media-list &:hover {
    text-decoration: none;

    .username {
      text-decoration: underline;
    }
  }
}


/**
 * Blockquotes
 */
blockquote {
  color: inherit;
  @include relative-font-size(1.125);
  font-style: normal;
  position: relative;

  > :last-child {
    margin-bottom: 0;
  }
}

blockquote cite {
  font-style: normal;
  display: block;
  text-align: right;
  font-size: 0.8em;
  margin-top: $spacing-unit;
}

blockquote {
  margin: 30px 0; /* Adjust the value as needed */
  box-shadow: 0 0 20px 5px rgba(0, 0, 0, 0.1); /* Adjust values as needed */
  padding: 20px;
}

// Define a map of background classes to border colors
$note-backgrounds: (
  'bruck': var(--border-color-bruck),
  'tinkering': var(--border-color-tinkering),
  'roughingOut': var(--border-color-roughingOut),
  'moulding': var(--border-color-moulding)
);

// Loop through the map and apply the styles
@each $class, $border-color in $note-backgrounds {
  .note-container.#{$class} blockquote {
    border-left-color: $border-color;
  }
}


/**
 * Code formatting
 */
pre,
code {
  font-family: $code-font-family;
  font-size: 0.9375em;
  border: 1px solid $border-color-01;
  border-radius: 3px;
  background-color: $code-background-color;
}

code {
  padding: 1px 5px;
}

pre {
  padding: 8px 12px;
  overflow-x: auto;

  > code {
    border: 0;
    padding-right: 0;
    padding-left: 0;
  }
}

.highlight {
  border-radius: 3px;
  background: $code-background-color;
  @extend %vertical-rhythm;

  .highlighter-rouge & {
    background: $code-background-color;
  }
}



/**
 * Wrapper
 */
.wrapper {
  max-width: calc(#{$content-width} - (#{$spacing-unit}));
  margin-right: auto;
  margin-left: auto;
  padding-right: $spacing-unit * .5;
  padding-left: $spacing-unit * .5;
  @extend %clearfix;

  @media screen and (min-width: $on-large) {
    max-width: calc(#{$content-width} - (#{$spacing-unit} * 2));
    padding-right: $spacing-unit;
    padding-left: $spacing-unit;
  }
}



/**
 * Clearfix
 */
%clearfix:after {
  content: "";
  display: table;
  clear: both;
}



/**
 * Icons
 */

.orange {
  color: #f66a0a;
}

.grey {
  color: #828282;
}

.svg-icon {
  width: 1.25em;
  height: 1.25em;
  display: inline-block;
  fill: currentColor;
  vertical-align: text-bottom;
}


/**
 * Tables
 */
table {
  margin-top: $spacing-unit;
  margin-bottom: $spacing-unit;
  width: 100%;
  height: auto;
  padding: 10px 0; /* 10px padding top and bottom, 0 padding left and right */
  text-align: $table-text-align;
  font-size: 0.8em;
  border-collapse: collapse;
  border: 1px solid $table-border-color;
  tr {
    &:hover {
      background-color: rgba(255, 255, 255, 0.75);
    }
  }
  th, td {
    padding: ($spacing-unit * 33.3333333333 * .01) ($spacing-unit * .5);
  }
  th {
    background-color: rgba(0, 0, 0, 0.05);
    border: 1px solid rgba(0, 0, 0, 0.25);
  }
  td {
    border: 1px solid rgba(0, 0, 0, 0.25);
  }

  @include media-query($on-laptop) {
    display: block;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
            -ms-overflow-style: -ms-autohiding-scrollbar;
  }
}


/**
 * Footnotes
 */

.footnotes {
  margin-top: 1.5rem;
  padding-top: 1.5rem;
  border-top: 1px solid rgba(0, 0, 0, 0.25);
  font-size: 1em;
  color: #606060;
}

.footnotes ol {
  color: #606060;
  font-size: 0.8em;
  padding-left: 1rem;
}

.footnotes ol li {
  margin-bottom: 0.5rem;
}

.footnotes p {
  margin: 0;
}

a.footnote {
  font-size: 0.8em;
  text-decoration: none;
  background-color: #f0f0f0;
  padding: 0 3px;
  border-radius: 3px;
  vertical-align: baseline;
  position: relative;
  top: -0.5em;
  line-height: 1;
  display: inline-flex;
  align-items: center;
}

@media (max-width: 600px) {
  a.footnote {
    font-size: 0.9em;
    padding: 1px 4px;
  }
}

a.footnote:hover {
  background-color: #e0e0e0;
  transition: background-color 0.3s ease;
}

/**
 * Superscript-wrangling
 */

sup[role="doc-noteref"] {
  vertical-align: baseline;
}